import React from 'react';
import './PricingPage.css';

const PricingPage = () => {
  return (
    <div className="pricing-page">
      <div className="pricing-plan basic">
        <h2>Basic Plan</h2>
        <div className="price">$29/user/month</div>
        <button onClick={(e) => {
          e.preventDefault();
          window.location.href='https://portal.voiceproconnect.com/signup';
        }}>Buy Now</button>
        <ul>
          <li>*Unlimited calls (US)</li>
          <li>Up to 70 users</li>
          <li>SMS support</li>
          <li>Unlimited Email to Fax</li>
          <li>Unlimited Fax to Email</li>
          <li>Unlimited Audio Conferencing</li>
          <li>Quality of Service Reports</li>
          <li>Call Log Reports</li>
          <li>Call Queue</li>
          <li>Auto Attendants</li>
          <li>Time based rules for call Routing</li>
          <li>IVR</li>
          <li>Hunt Groups</li>
          <li>Voicemail to Email</li>
          <li>Basic support</li>
        </ul>

      </div>
      <div className="pricing-plan premium">
        <h2>Premium Plan</h2>
        <div className="price">$39/user/month</div>
        <button  onClick={(e) => {
          e.preventDefault();
          window.location.href='https://portal.voiceproconnect.com/signup';
        }}>Buy Now</button>
          <ul>
            <li>*Unlimited calls (US)</li>
            <li>Up to 200 users</li>
            <li>SMS support</li>
            <li>Unlimited Email to Fax</li>
            <li>Unlimited Fax to Email</li>
            <li>Unlimited Audio Conferencing</li>
            <li>Call Log Reports</li>
            <li>Call Recording</li>
            <li>Call Queue</li>
            <li>Auto Attendants</li>
            <li>Time based rules for call Routing</li>
            <li>IVR</li>
            <li>Hunt Groups</li>
            <li>Voicemail transcription</li>
            <li>Voicemail to Email</li>
            <li>Includes VoIP Phone</li>
            <li>Priority support</li>
          </ul>
      </div>
      <div className="pricing-plan enterprise">
        <h2>Enterprise Plan</h2>
        <div className="price">Call Sales</div>
        <button  onClick={(e) => {
          e.preventDefault();
          window.location.href='https://portal.voiceproconnect.com/signup';
        }}>Contact Us</button>
        <ul>
          <li>*Unlimited calls (US)</li>
          <li>Unlimited Users</li>
          <li>SMS support</li>
          <li>Unlimited Email to Fax</li>
          <li>Unlimited Fax to Email</li>
          <li>Unlimited Audio Conferencing</li>
          <li>Quality of Service Reports</li>
          <li>Call Log Reports</li>
          <li>Call Recording</li>
          <li>Call Queue</li>
          <li>Auto Attendants</li>
          <li>Time based rules for call Routing</li>
          <li>IVR</li>
          <li>Hunt Groups</li>
          <li>Voicemail transcription</li>
          <li>Voicemail to Email</li>
          <li>Includes VoIP Phone</li>
          <li>Single Sign On</li>
          <li>Custom API Integrations</li>
          <li>Dedicated 24/7 support</li>
        </ul>

      </div>
    </div>
  );
};

export default PricingPage;
