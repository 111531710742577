import React, { useState } from 'react';
import './SupportPage.css'; // Ensure you style it accordingly

const SupportPage = () => {
    const [openSection, setOpenSection] = useState('gettingStarted');

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? '' : section);
    };

    return (
        <div className={'support-page'}>
            <h2>Support Center</h2>
            <p>Welcome to our Support Center. We're here to ensure your experience with our VoIP services is seamless.</p>

            <div className="accordion" onClick={() => toggleSection('gettingStarted')}>
                Getting Started
            </div>
            <div className={`panel ${openSection === 'gettingStarted' ? 'open' : ''}`}>
                <p><strong>Creating Your Account:</strong> Begin by visiting our sign-up page. Fill in your details, and you'll receive an email to confirm your account. Follow the instructions within to activate your account and start using our services.</p>
                <p><strong>Setting Up Your VoIP Service:</strong> After account creation, log in to your dashboard. Use our setup wizard to choose your VoIP number and configure essential settings. You can also download user guides for more detailed setups.</p>
                <p><strong>Downloading and Installing Softphone Applications:</strong> We support a variety of softphone applications. Visit the "Downloads" section in your account to see our recommended apps. Follow the installation guides provided for each to get started.</p>
            </div>

            <div className="accordion" onClick={() => toggleSection('managingYourAccount')}>
                Managing Your Account
            </div>
            <div className={`panel ${openSection === 'managingYourAccount' ? 'open' : ''}`}>
                <p><strong>Updating Account Information:</strong> Keep your account details up-to-date by accessing the "Account Settings" section. Here you can change your contact information, password, and billing details.</p>
                <p><strong>Understanding Your Bill:</strong> Each month, you'll receive a detailed bill via email. It includes your subscription fees, usage charges, and any additional service fees. For any queries, our billing team is ready to assist you.</p>
                <p><strong>Adding or Removing Services:</strong> To adjust your service package, visit the "Services" section in your dashboard. Here you can easily add new features to your plan or remove services you no longer need.</p>
            </div>

            <div className="accordion" onClick={() => toggleSection('troubleshooting')}>
                Troubleshooting
            </div>
            <div className={`panel ${openSection === 'troubleshooting' ? 'open' : ''}`}>
                <p><strong>Connection Issues:</strong> Start by checking your internet connection and router settings. A quick reboot might resolve many connectivity issues. If problems persist, consult our online troubleshooting guide or contact support.</p>
                <p><strong>Call Quality Problems:</strong> Poor call quality can often be improved by adjusting your bandwidth settings or using a wired connection instead of Wi-Fi. Our support site offers a list of tips and tweaks to enhance call clarity.</p>
                <p><strong>VoIP Hardware Setup:</strong> Ensure all hardware is properly connected and configured. Our online guides provide step-by-step instructions for setting up various VoIP devices and accessories.</p>
                <p><strong>Software Configuration:</strong> Keeping your software up-to-date is crucial for optimal performance. Check for updates regularly, and refer to our configuration guides to adjust settings as needed.</p>
            </div>

            <div className="accordion" onClick={() => toggleSection('faqs')}>
                FAQs
            </div>
            <div className={`panel ${openSection === 'faqs' ? 'open' : ''}`}>
                <p><strong>What is VoIP and how does it work?</strong> VoIP (Voice over Internet Protocol) technology allows you to make voice calls using a broadband Internet connection instead of a regular phone line. It converts your voice into a digital signal that travels over the Internet.</p>
                <p><strong>How can I improve my call quality?</strong> Ensuring a strong and stable internet connection is key. Use Ethernet connections where possible, close bandwidth-intensive applications, and consider upgrading your internet plan if necessary.</p>
                <p><strong>What kind of internet connection do I need?</strong> A broadband internet connection with at least 100 kbps of upload and download speed per VoIP line is recommended for optimal performance.</p>
                <p><strong>Are there any additional fees?</strong> Our pricing is transparent, with no hidden fees. You're billed for your subscription plan, any additional usage beyond your plan's allowances, and optional add-on services.</p>
            </div>
        </div>
    );
};

export default SupportPage;
