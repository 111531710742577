import React from 'react';
import './SolutionsPage.css';

const SolutionsPage = () => {
    return (
        <div className="section-container solutions">
            <section className="section-one">
                <div className="section-group">
                    <div className="floating-text">
                        <h2>Dealerships</h2>
                        <p>In the fast-paced automotive dealership industry, communication is the engine that drives
                            success. Our VoIP solutions are designed to turbocharge your dealership's connectivity,
                            offering crystal-clear voice communication across showrooms and service centers. With
                            features such as multi-extension dialing, call forwarding, and mobile integration, your
                            sales and service teams can stay connected with customers and each other, whether on the
                            floor or on the go. Enhance your customer service with efficient, uninterrupted
                            communication, driving sales and customer satisfaction to new heights.</p>

                    </div>
                    <div className="floating-image2"></div>
                </div>


            </section>
            <section className="section-two">
                <div className="section-group grop2">
                    <div className="floating-text text2">
                        <h2>Healthcare</h2>
                        <p>In the critical world of healthcare, every second and every communication matters. Our VoIP solutions provide the lifeline healthcare facilities need to ensure seamless, secure, and reliable communication among healthcare professionals and with patients. From telemedicine consultations to confidential voicemail services, our VoIP technology ensures that healthcare providers can offer timely, compassionate care. With advanced features such as call encryption for patient privacy and priority call routing for emergencies, our solutions support the healthcare sector in saving lives and maintaining wellbeing.</p>

                    </div>
                    <div className="floating-image image2">
                    </div>
                </div>
            </section>
            <section className="section-one">
                <div className="section-group">
                    <div className="floating-text">
                        <h2>Retail</h2>
                        <p>In the dynamic retail industry, excellent customer service and efficient internal communication are key to success. Our VoIP solutions empower retailers with flexible, scalable communication tools that adapt to seasonal demands and business growth. Benefit from features like virtual attendants, call distribution, and real-time customer feedback collection. Whether connecting multiple store locations, managing remote customer service teams, or enhancing the in-store experience, our VoIP services help retailers stay ahead in a competitive market, ensuring every customer call leads to a positive outcome.</p>

                    </div>
                    <div className="floating-image3"></div>
                </div>


            </section>

            <section className="section-three">
                <div className="section-group2">
                    <div className="floating-text text3">
                        <h2>Custom API Integrations</h2>
                        <p>Looking to streamline your business operations and take them to the next level? Our custom
                            API
                            integrations provide a solution that can simplify and optimize your workflows, making your
                            business
                            more efficient and effective.
                            We understand that every business has unique needs, which is why we work with you to create
                            tailored
                            integrations that perfectly match your requirements. Experience the power of seamless
                            integration
                            with our custom API solutions today. </p>
                    </div>
                    <div className="section-footer-logos">
                        <div className="ailogify-logo">
                            <img src="./assets/ailogify_logo@3x.png" height="36.78" width="123.62" alt="ailogify"/>
                        </div>
                        <div className="pci-logo">
                            <img src="./assets/pci@3x.png" height="44" width="83" alt="ailogify"/>
                        </div>
                        <div className="sas-logo">
                            <img src="./assets/sas70@3x.png" height="40" width="106" alt="ailogify"/>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default SolutionsPage;
