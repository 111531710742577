import React from 'react';
import './TermsOfUse.css';

// Include CSS file for styling if needed

function TermsOfUse() {
    return (
        <div className={'terms'}>
            <p>These Terms and Conditions ("Terms") govern your use of VoicePro Communications, Inc (the "Company")
                online
                portal
                (the "Portal"). Please read these Terms carefully. By accessing or using the Portal, you agree to be
                bound
                by these Terms.</p>
            <ol>
                <li>
                    <p><strong>Acceptance of Terms:</strong> You acknowledge that you have read, understood, and agree
                        to be
                        bound by these Terms. If you do not accept these Terms, you are not authorized to use the
                        Portal.</p>
                </li>
                <li>
                    <p><strong>Registration:</strong> To access certain features of the Portal, you may be required to
                        register. You agree to provide accurate and complete information during the registration
                        process.</p>
                </li>
                <li>
                    <p><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of
                        your
                        account credentials and for all activities that occur under your account. You agree to notify
                        the
                        Company immediately of any unauthorized use of your account.</p>
                </li>
                <li>
                    <p><strong>Data Collection:</strong> By using the Portal, you acknowledge and agree that the Company
                        may
                        collect certain information about you, such as your IP address, browser type, the date and time
                        of
                        your visit, and other user activity data. This information is used to improve the quality and
                        relevance of our services.</p>
                </li>
                <li>
                    <p><strong>Content:</strong> All text, graphics, user interfaces, visual interfaces, photographs,
                        trademarks, logos, and computer code (collectively, "Content"), is owned, controlled, or
                        licensed by
                        the Company and is protected by trade dress, copyright, patent, and trademark laws, and various
                        other
                        intellectual property rights and unfair competition laws.</p>
                </li>
                <li>
                    <p><strong>User Conduct:</strong> You agree not to use the Portal in any manner that could damage,
                        disable, overburden, or impair it, or interfere with any other party's use and enjoyment of the
                        Portal. You further agree not to copy, share, distribute, or disclose any content from the
                        Portal
                        without the express written permission of the Company.</p>
                </li>
                <li>
                    <p><strong>Privacy Policy:</strong> The Company's Privacy Policy, available at
                        https://voiceproconnect.com/privacyPolicy.html , is hereby incorporated into these Terms. By
                        using the
                        Portal, you acknowledge and agree that internet transmissions are never completely private or
                        secure.
                    </p>
                </li>
                <li>
                    <p><strong>Third-Party Sites:</strong> The Portal may contain links to third-party websites. The
                        Company
                        is not responsible for the content, accuracy or opinions expressed in such websites.</p>
                </li>
                <li>
                    <p><strong>Termination:</strong> The Company may terminate or suspend your access to the Portal
                        without
                        prior notice or liability, for any reason or for no reason, including without limitation, if you
                        breach these Terms.</p>
                </li>
                <li>
                    <p><strong>Disclaimer of Warranties:</strong> The Portal is provided "as is." The Company makes no
                        representation or warranty of any kind whatsoever relating to the Portal, linked sites, or other
                        content that may be accessible directly or indirectly through the Portal.</p>
                </li>
                <li>
                    <p><strong>Limitation of Liability:</strong> In no event will the Company be liable for any damages,
                        including without limitation direct or indirect, special, incidental, or consequential damages,
                        losses
                        or expenses arising in connection with this Portal or use thereof or inability to use by any
                        party.
                    </p>
                </li>
                <li>
                    <p><strong>Changes to Terms:</strong> The Company reserves the right, at its sole discretion, to
                        change,
                        modify, add or remove portions of these Terms at any time. It is your responsibility to check
                        these
                        Terms periodically for changes.</p>
                </li>
                <li>
                    <p><strong>Governing Law:</strong> These Terms are governed by the laws of the State of New Jersey,
                        United States, without regard to its conflict of laws provisions.</p>
                </li>
                <li>
                    <p><strong>Contact Us:</strong> If you have any questions about these Terms, please contact us at
                        Toll-free: +1 (877) 233-3433.</p>
                </li>
            </ol>
            <p>BY USING THE PORTAL, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS AND CONDITIONS, UNDERSTAND THEM, AND
                AGREE TO BE BOUND BY THEM.</p>
        </div>
    );
}

export default TermsOfUse;
