import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <div className="about-us">
            <h1>Welcome to VoicePro Communications</h1>
            <p>
                Where pioneering communication technology meets business expertise. For over 16 years,
                we've dedicated ourselves to revolutionizing the way businesses communicate, offering
                state-of-the-art Voice over Internet Protocol (VoIP) solutions that empower organizations
                across various industries.
            </p>
            <h2>Our Expertise</h2>
            <p>
                With a rich history spanning over a decade and a half, we've amassed extensive experience
                in delivering customized communication solutions. Our expertise lies not just in VoIP
                technology but in understanding the unique challenges and opportunities within various
                sectors. From automotive dealerships and healthcare facilities to retail chains and beyond,
                our tailored solutions ensure that every client enjoys enhanced connectivity, productivity,
                and customer satisfaction.
            </p>
            <h2>Pioneering New Technologies</h2>
            <p>
                At VoicePro Communications, staying ahead of the curve is not just a goal — it's our norm. Our
                in-house research and development team continuously explores new technologies, pushing
                the boundaries of what's possible in VoIP communications. Whether it's integrating AI for
                smarter call routing, leveraging encryption for secure transactions, or adopting IoT for
                enhanced connectivity, we are at the forefront of the digital revolution, ensuring our
                clients benefit from the latest and most efficient communication tools.
            </p>
            <h2>Serving Multiple Industries</h2>
            <p>
                Our success is built on a foundation of versatility and adaptability. Understanding that
                each industry faces its unique set of challenges, we've honed our ability to offer bespoke
                solutions that meet specific needs. This approach has enabled us to serve a diverse clientele,
                ensuring that regardless of their sector, our clients can rely on us for seamless communication
                solutions that drive their success.
            </p>
            <h2>Committed to Excellence</h2>
            <p>
                Our commitment to excellence is evident in every aspect of our business. From our customer
                service to our technical support, we strive to exceed expectations. Our team of skilled
                professionals is dedicated to providing outstanding service, ensuring that every interaction
                with us is positive and productive.
            </p>
            <h2>Looking Ahead</h2>
            <p>
                As we look to the future, we remain committed to our mission of enhancing business communication
                through innovative technology. With a solid foundation built on 16 years of experience and a
                clear vision for the future, we are excited about the possibilities that lie ahead.
            </p>
            <p>
                Thank you for considering VoicePro Communications as your trusted VoIP provider. We look forward
                to partnering with you and helping your business achieve unparalleled communication efficiency
                and success.
            </p>
        </div>
    );
}

export default AboutUs;
