import React from 'react';
import './CareersPage.css';
import {useParams} from "react-router-dom"; // Ensure to create a corresponding CSS file for styling

const Careers = () => {
    const {id} = useParams()
    const jobs = [
        {
            location: 'NJ/NY',
            title: 'Software Engineers with Telecommunications Background',
            id: 'software-engineer-telecom-njny'
        },
        {
            location: 'FL',
            title: 'Customer Success Managers',
            id: 'customer-success-manager-fl'
        },
        {
            location: 'FL',
            title: 'Project Manager with Telecommunications Background',
            id: 'project-manager-telecom-fl'
        },
        {
            location: 'TX',
            title: 'Sales Representative',
            id: 'sales-rep-tx'
        }
    ];

    if (id ==='software-engineer-telecom-njny')
        return (
            <div className="careersContainer">
                <h2>Software Engineer with Telecommunications Background</h2>
                <h3>Location: NJ/NY</h3>
                <p>We are seeking experienced software engineers with a background in telecommunications to join our
                    NJ/NY team. Ideal candidates will have expertise in developing scalable software solutions and a
                    strong understanding of telecommunications networks.</p>

                <h4>Responsibilities:</h4>
                <ul>
                    <li>Design, develop, and maintain software for telecommunications systems.</li>
                    <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                    <li>Ensure the performance, quality, and responsiveness of applications.</li>
                    <li>Identify and correct bottlenecks and fix bugs.</li>
                    <li>Help maintain code quality, organization, and automatization.</li>
                </ul>

                <h4>Qualifications:</h4>
                <ul>
                    <li>Bachelor's degree in Computer Science, Engineering, or related field.</li>
                    <li>Proven software development experience in telecommunications.</li>
                    <li>Strong knowledge of Java, C++, or other programming languages.</li>
                    <li>Experience with network protocols and socket programming.</li>
                    <li>Excellent problem-solving skills and attention to detail.</li>
                </ul>

                <p>To apply, please submit your resume and cover letter outlining your qualifications and
                    experience.</p>
            </div>
        );
    else if (id==='customer-success-manager-fl')
        return (
            <div className="careersContainer">
                <h2>Customer Success Manager</h2>
                <h3>Location: FL</h3>
                <p>Our Florida team is expanding, and we're in search of a Customer Success Manager to ensure our
                    clients achieve their goals while using our product. This role involves managing client
                    relationships, onboarding new clients, and fostering account growth.</p>

                <h4>Responsibilities:</h4>
                <ul>
                    <li>Develop strong relationships with customers, connecting with key business executives and
                        stakeholders.
                    </li>
                    <li>Guide customers through the onboarding process and ensure their success with our product.</li>
                    <li>Prepare reports on account status and track customer success metrics.</li>
                    <li>Identify opportunities for growth within existing accounts and collaborate with sales teams to
                        ensure growth attainment.
                    </li>
                    <li>Assist with challenging client requests or issue escalations as needed.</li>
                </ul>

                <h4>Qualifications:</h4>
                <ul>
                    <li>Proven work experience as a Customer Success Manager or similar role.</li>
                    <li>Experience in providing customer service support.</li>
                    <li>Excellent communication and negotiation skills.</li>
                    <li>Ability to build rapport and collaborate with customers.</li>
                    <li>BA/BS degree in Business Administration, Sales or relevant field.</li>
                </ul>

                <p>Interested candidates should submit their application, including a resume and a brief statement on
                    why you're the perfect fit for this role.</p>
            </div>
        )
    else if (id === 'project-manager-telecom-fl')
        return (
            <div className="careersContainer">
                <h2>Project Manager with Telecommunications Background</h2>
                <h3>Location: FL</h3>
                <p>We are looking for a seasoned Project Manager with a strong background in telecommunications to lead
                    our Florida-based projects. The ideal candidate will have experience managing complex projects and a
                    deep understanding of telecommunications infrastructure and services.</p>

                <h4>Responsibilities:</h4>
                <ul>
                    <li>Coordinate internal resources and third parties/vendors for the flawless execution of
                        projects.
                    </li>
                    <li>Ensure that all projects are delivered on-time, within scope, and within budget.</li>
                    <li>Developing project scopes and objectives, involving all relevant stakeholders and ensuring
                        technical feasibility.
                    </li>
                    <li>Manage changes to the project scope, project schedule, and project costs using appropriate
                        verification techniques.
                    </li>
                    <li>Measure project performance using appropriate systems, tools, and techniques.</li>
                </ul>

                <h4>Qualifications:</h4>
                <ul>
                    <li>Proven working experience as a project manager in the telecommunications sector.</li>
                    <li>Solid technical background, with understanding or hands-on experience in software development
                        and web technologies.
                    </li>
                    <li>Excellent client-facing and internal communication skills.</li>
                    <li>Bachelor's Degree in appropriate field of study or equivalent work experience.</li>
                </ul>

                <p>To apply for this position, please send your resume, cover letter, and any relevant project examples
                    or case studies.</p>
            </div>
        )
    else if (id === 'sales-rep-tx')
        return (
            <div className="careersContainer">
                <h2>Sales Representative</h2>
                <h3>Location: TX</h3>
                <p>Our Texas team is looking for a Sales Representative to help us expand our clientele. The ideal
                    candidate will have a keen interest in sales, a competitive spirit, and the ability to close deals
                    and meet sales quotas.</p>

                <h4>Responsibilities:</h4>
                <ul>
                    <li>Conduct market research to identify selling possibilities and evaluate customer needs.</li>
                    <li>Actively seek out new sales opportunities through cold calling, networking, and social media.
                    </li>
                    <li>Set up meetings with potential clients and listen to their wishes and concerns.</li>
                    <li>Prepare and deliver appropriate presentations on products/services.</li>
                    <li>Negotiate/close deals and handle complaints or objections.</li>
                </ul>

                <h4>Qualifications:</h4>
                <ul>
                    <li>Proven experience as a Sales Executive or relevant role.</li>
                    <li>Proficiency in English.</li>
                    <li>Thorough understanding of marketing and negotiating techniques.</li>
                    <li>Fast learner and passion for sales.</li>
                </ul>

                <p>Please submit your resume along with a cover letter explaining why you are the best candidate for
                    this role.</p>
            </div>
        )

    return (
        <div className="careersContainer">
            <h2>Careers at Our Company</h2>
            <p>Join our team and help us connect the world. We're looking for passionate individuals to fill the
                following positions:</p>

            <ul className="jobListings">
                {jobs.map((job) => (
                    <li key={job.id} className="jobListing">
                        <h3>{job.title}</h3>
                        <p>Location: {job.location}</p>
                        {/* Assuming each job listing will have a dedicated page or modal with more details */}
                        <a href={`/careers/${job.id}`}>View Details</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Careers;
