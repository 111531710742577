import React from "react";

export default function Header() {

    return (
        <div className="header-container">
            <header className="site-header">
                <div className="logo">
                    <a href="/">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="208.125"
                             height="56.965" viewBox="0 0 208.125 56.965">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="208.125" height="56.965"
                                          fill="none"
                                          stroke="#ff9200" stroke-width="1"/>
                                </clipPath>
                                <clipPath id="clip-path-2">
                                    <rect id="Rectangle_2" data-name="Rectangle 2" width="208.125" height="56.965"
                                          fill="none"/>
                                </clipPath>
                            </defs>
                            <g id="Group_13" data-name="Group 13" transform="translate(-365.811 -22)">
                                <g id="Group_2" data-name="Group 2" transform="translate(365.811 22)">
                                    <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
                                        <text id="V" transform="translate(0 31.465)" fill="#f7981d" stroke="#ff9200"
                                              stroke-width="1" font-size="36" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">V</tspan>
                                        </text>
                                        <text id="oice" transform="translate(22.047 31.465)" fill="#f7981d"
                                              stroke="#ff9200"
                                              stroke-width="1" font-size="37" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">oice</tspan>
                                        </text>
                                        <text id="PRO" transform="translate(96.443 31.465)" fill="#979797"
                                              font-size="37"
                                              font-family="Helvetica-BoldOblique, Helvetica" font-weight="700"
                                              font-style="oblique">
                                            <tspan x="0" y="0">PRO</tspan>
                                        </text>
                                        <text id="T" transform="translate(181.715 8.75)" fill="#989897" font-size="7"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">T</tspan>
                                        </text>
                                        <text id="M" transform="translate(187.015 9.75)" fill="#989897" font-size="8"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">M</tspan>
                                        </text>
                                        <path id="Path_1" data-name="Path 1"
                                              d="M24.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(4.024 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(20.838 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                        <path id="Path_2" data-name="Path 2"
                                              d="M15.25,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(1.87 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(9.685 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                                <text id="communications" transform="translate(411.026 67.416)" fill="gray"
                                      font-size="13"
                                      font-family="Helvetica" letter-spacing="0.229em">
                                    <tspan x="0" y="0">communications</tspan>
                                </text>
                                <g id="Group_4" data-name="Group 4" transform="translate(365.811 22)">
                                    <g id="Group_3" data-name="Group 3" clip-path="url(#clip-path-2)">
                                        <path id="Path_3" data-name="Path 3"
                                              d="M33.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(6.178 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(31.992 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
                <nav className="site-nav">
                    <ul className="menu">
                        <li><a href="/solutions">Solutions</a></li>
                        <li><a href="/pricing">Pricing</a></li>
                        <li><a href="/support">Support</a></li>
                    </ul>
                </nav>
                <nav className="site-nav2">
                    <ul className="menu">
                        <li><a href="#">Call Us:</a></li>
                        <li>1-855-444-0060</li>
                    </ul>
                </nav>
                <div className="header-buttons">
                    <a href="https://portal.voiceproconnect.com/#/login" className="login-button">Log In</a>
                </div>
                <div className="burger-menu">
                    <i className="fa fa-bars"></i>
                </div>
            </header>
        </div>
    )
};
