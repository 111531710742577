import React from 'react';
import {BrowserRouter as Router, Route, Routes, useParams} from 'react-router-dom';
import './App.css';
import PricingPage from './components/pricing';
import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/footer";
import SupportPage from "./components/support";
import Solutions from "./components/solutions";
import AboutUs from "./components/aboutus";
import ContactUs from "./components/contactus";
import TermsOfUse from "./components/termsofuse";
import Careers from "./components/careers";

function App() {
    const {id} = useParams()
    return (
        <Router>
            <Routes>
                <Route path="/" element={<><Header/>
                        <Main/>
                        <Footer/></>}
                />
                <Route path="/pricing" element={<><Header/>
                    <PricingPage/>
                    <Footer/></>}
                />
                <Route path="/support" element={<><Header/>
                    <SupportPage/>
                    <Footer/></>}
                />
                <Route path="/billing" element={<><Header/>
                    <SupportPage/>
                    <Footer/></>}
                />
                <Route path="/faq" element={<><Header/>
                    <SupportPage/>
                    <Footer/></>}
                />
                <Route path="/solutions" element={<><Header/>
                    <Solutions/>
                    <Footer/></>}
                />
                <Route path="/about" element={<><Header/>
                    <AboutUs/>
                    <Footer/></>}
                />

                <Route path="/contact" element={<><Header/>
                    <ContactUs/>
                    <Footer/></>}
                />
                <Route path="/terms" element={<><Header/>
                    <TermsOfUse/>
                    <Footer/></>}
                />

                <Route path="/privacy" element={<><Header/>
                    <TermsOfUse/>
                    <Footer/></>}
                />
                <Route path="/service" element={<><Header/>
                    <TermsOfUse/>
                    <Footer/></>}
                />
                <Route path="/careers" element={<><Header/>
                    <Careers/>
                    <Footer/></>}
                />
                <Route path="/careers/:id" element={<><Header/>
                    <Careers id={id}/>
                    <Footer/></>}
                />

            </Routes>
        </Router>
    );
}

export default App;
